import { Link } from "gatsby";
import React from "react";
import Container from "../components/container";
import Layout from "../components/layouts/main-layout";

const NotFound = () => {
  return (
    <Layout>
      <Container>
        <h1>MOIN, das ist schief gelaufen. Seite vom Winde verweht. #404</h1>
        <Link to="/">Zur Startseite</Link>
      </Container>
    </Layout>
  );
};

export default NotFound;
